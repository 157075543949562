<template>
  <v-container
    fill-height
    fluid
    style="padding: 0"
    class="d-flex justify-center login-container"
  >
    <v-dialog v-model="dialog" width="300">
      <v-card>
        <v-card-title>Contact Support</v-card-title>

        <v-card-text>
          If you need to contact our support team, send us an email to
          {{ contact }}.
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" text @click="openEmail(contact)">
            Open Email
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="copyToClipboard(contact)">
            Copy Email
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="ma-6">
      <div class="d-flex flex-column align-center justify-center">
        <img
          :src="require('../../assets/svg/pagoda_square_prelude_connect.svg')"
          width="240px"
          height="180px"
        />
      </div>
      <v-card id="login-card" class="mb-6">
        <v-container>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-form ref="loginForm" align="center" class="ma-1">
                <div class="d-flex" style="margin: 1px 6px 57px 6px">
                  <div class="mr-auto decoration-button">
                    <label class="label-button" @click="dialog = true">
                      Contact Support
                    </label>
                  </div>

                  <div class="decoration-button">
                    <label class="label-button" @click="changeTab(1)">
                      Forgot Password
                    </label>
                  </div>
                </div>

                <div :style="marginInput">
                  <TextField
                    v-model="email"
                    placeholder="Email"
                    :rules="emailRules"
                    required
                    outlined
                    dense
                  />
                  <TextField
                    v-model="password"
                    placeholder="Password"
                    :rules="[(v) => !!v || 'password is required']"
                    outlined
                    dense
                    :append-icon="hidepass ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="hidepass = !hidepass"
                    :type="hidepass ? 'password' : 'text'"
                  />
                  <v-btn
                    color="primary"
                    v-if="devmode"
                    block
                    outlined
                    :loading="loading"
                    @click="devLogin"
                    class="mr-2 mb-2"
                  >
                    Dev Login
                  </v-btn>
                  <v-btn
                    block
                    depressed
                    rounded
                    color="#A0D5D7"
                    :loading="loading"
                    @click.prevent="onLogin"
                  >
                    Sign In
                  </v-btn>
                </div>
                <div class="d-flex" style="margin: 6px 6px 6px 6px">
                  <div class="mr-auto decoration-button"></div>
                  <div>
                    <label class="label-version">
                      {{ version }}
                    </label>
                  </div>
                </div>
              </v-form>
            </v-tab-item>
            <v-tab-item>
              <div class="ma-1">
                <div
                  class="d-flex justify-center"
                  style="margin: 1px 6px 57px 6px"
                >
                  <div class="decoration-button">
                    <label class="label-button" @click="changeTab(0)">
                      Back to Login
                    </label>
                  </div>
                </div>
                <div :style="marginInput">
                  <TextField
                    v-model="email"
                    placeholder="Email"
                    :rules="emailRules"
                    required
                    outlined
                    dense
                  />
                  <v-btn
                    block
                    depressed
                    rounded
                    dark
                    color="#1F6070"
                    :loading="loading"
                    @click="onForgot"
                  >
                    Send Reset Code
                  </v-btn>
                </div>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-container>
        <LoginFooter v-if="$vuetify.breakpoint.smAndDown" class="pb-4" />
      </v-card>
      <Notify
        v-if="notification.show"
        :width="notification.width"
        :type="notification.type"
        no-title
        @close="notification.show = false"
        @consent="changeTab(0)"
        :message="notification.message"
      />
    </div>
  </v-container>
</template>

<script>
import LoginFooter from './LoginFooter.vue';
import { api, auth, partner } from '../../sharedPlugin';
import { PublicClientApplication } from '@azure/msal-browser';
import axios from 'axios';

export default {
  components: { LoginFooter },
  data: () => ({
    tab: 0,
    validLogin: false,
    email: '',
    password: '',
    url: '',
    hidepass: true,
    loading: false,
    devmode: process.env.NODE_ENV === 'development',
    notification: {
      width: 0,
      type: false,
      show: false,
      message: null
    },
    contact: 'support@preludeconnect.com',
    dialog: false,
    version: null
  }),
  computed: {
    marginInput() {
      return this.$vuetify.breakpoint.smAndDown
        ? 'margin: 0 22px 56px 22px'
        : 'margin: 0 72px 56px 72px';
    },
    isNotProd() {
      return process.env.VUE_APP_ENV !== 'production';
    },
    cryo() {
      return this.url.includes('cryo') ? true : false;
    },
    emailRules() {
      return [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ];
    }
  },
  watch: {
    alert(value) {
      if (value) {
        setTimeout(() => {
          this.alert = false;
        }, 5000);
      }
    }
  },
  mounted() {
    this.setLocalPartner(null);
    this.url = window.location.href;
    this.loadVersion();
  },
  methods: {
    ...auth.mapMethods(['login', 'setUser']),
    ...partner.mapMethods(['setLocalPartner']),
    onLogin() {
      if (this.$refs.loginForm.validate()) {
        this.loading = true;
        let input = {
          email: this.email,
          password: this.password,
          version: 10000
        };
        if (this.isNotProd) {
          input = {
            ...input
          };
        }
        this.login({
          input: input,
          saveCredentials: true
        })
          .then(() =>
            this.$router.push({ path: this.$route.query.redirect ?? '/' })
          )
          .catch((errorMessage) => {
            this.notification.width =
              document.getElementById('login-card').offsetWidth;
            this.notification.type = 'error';
            this.notification.show = true;
            this.notification.message =
              errorMessage ?? 'Oops! Something went wrong. Please try again.';
          })
          .finally(() => (this.loading = false));
      }
    },
    devLogin() {
      this.loading = true;

      let input = {
        email: process.env.VUE_APP_DEV_EMAIL ?? 'dev3@noemail.com',
        password: process.env.VUE_APP_DEV_PASSWORD ?? 'Test1234!!!',
        version: 10000
      };
      if (this.isNotProd) {
        input = {
          ...input
        };
      }
      this.login({
        input: input,
        saveCredentials: true
      })
        .then(() =>
          this.$router.push({ path: this.$route.query.redirect ?? '/' })
        )
        .finally(() => (this.loading = false));
    },
    async microsoftSignIn() {
      this.loading = true;
      try {
        const msal = new PublicClientApplication({
          auth: {
            clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
            authority:
              'https://login.microsoftonline.com/74cbe500-380f-46da-8078-fdc006248ccc',
            redirectUri: process.env.VUE_APP_URL
          },
          cache: {
            cacheLocation: 'localStorage'
          }
        });
        const result = await msal.loginPopup({
          scopes: [
            'https://graph.microsoft.com/openid',
            'https://graph.microsoft.com/profile',
            'https://graph.microsoft.com/email'
          ]
        });
        const login = await api.Auth.ehrUserLogin(result.accessToken);
        this.setUser({
          user: { ...login, email: result.account.username },
          save: true
        });
        this.$router
          .push({
            path:
              this.$route.query.redirect === '/'
                ? '/messages'
                : this.$route.query.redirect
          })
          .catch(() => null);
      } catch (e) {
        this.notification.width =
          document.getElementById('login-card').offsetWidth;
        this.notification.type = 'error';
        this.notification.show = true;
        this.notification.message = 'Error logging into microsoft account';
      }
      this.loading = false;
    },
    onForgot() {
      this.loading = true;
      api.Auth.forgot({ email: this.email })
        .then(({ data }) => {
          this.notification.width =
            document.getElementById('login-card').offsetWidth;
          this.notification.type = 'success';
          this.notification.show = true;
          this.notification.message =
            data ?? 'Please check your email for a reset code.';
        })
        .catch(({ message }) => {
          this.notification.width =
            document.getElementById('login-card').offsetWidth;
          this.notification.type = 'error';
          this.notification.show = true;
          this.notification.message =
            message ?? 'Oops! Something went wrong. Please try again.';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    copyToClipboard(textToCopy) {
      navigator.clipboard.writeText(textToCopy);
      this.dialog = false;
    },
    changeTab(tab) {
      this.tab = tab;
      this.notification.show = false;
    },
    openEmail(email) {
      window.open(`mailto:${email}`);
      this.dialog = false;
    },
    loadVersion() {
      axios.get(`${window.origin}/version.json`).then((response) => {
        this.version = response.data.version;
      });
    }
  }
};
</script>

<style lang="scss">
.login-container {
  background: linear-gradient(
    180deg,
    #224d81 0%,
    #36bebf 146.99%,
    #eff2f1 246.91%
  );
  height: 100%;
}

#login-card.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
}

#login-card {
  border-radius: 16px;
}

.label-button {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #000000;
}

.label-version {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: gray;
}

.label-button:hover {
  cursor: pointer;
}

.decoration-button {
  border-bottom: 1px solid #d6366e;
}

.logo {
  width: 108em;
  height: 108em;

  &--small {
    width: 90em;
    height: 90em;
  }
}
</style>
